/* tslint:disable */

/**
 */
export class VehicleLogForm {
    date?: string;
    route_id?: number;
    vehicle_id?: number;
    user_id?: number;
}
