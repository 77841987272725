/* tslint:disable */
import { CompanyEInvoice } from './company-einvoice';

/**
 */
export class Company {
    phone?: string;
    id?: number;
    subname?: string;
    fullname?: string;
    address?: string;
    print_at?: string;
    tax_code?: string;
    name?: string;
    logo?: string;
    email?: string;
    lat?: number;
    lng?: number;
    created_at?: string;
    updated_at?: string;
    layout_cards?: string;
    started_at?: string;
    ended_at?: string;
    parent_id?: number;
    e_invoice?: CompanyEInvoice;
}
