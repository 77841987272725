/* tslint:disable */

/**
 */
export class CompanyEInvoiceHilo {
  push_auto?: boolean;
  passenger_ticket?: boolean;
  goods_ticket?: boolean;
  url?: string;
  username?: string;
  password?: string;
  taxcode?: string;
  pattern?: string;
  serial?: string;
}
